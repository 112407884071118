.left-wrapper {
  // width: 100%;
  h3 {
    color: #5a5e64;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    @media only screen and (min-width: 1600px) {
      font-size: 18px;
    }
  }
  h4 {
    color: #0d1321;
    margin-bottom: 16px;
    font-size: 32px;
    font-weight: 700;
    max-width: 432px;
    width: 100%;
    line-height: 1.3;

    @media only screen and (min-width: 1600px) {
      font-size: 34px;
    }
  }
  p {
    color: #42474d;
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    max-width: 421px;
    width: 100%;
    @media only screen and (min-width: 1600px) {
      font-size: 20px;
    }
  }
  .card-wrapper {
    border-top: 1px solid #cbd1d9 !important;
    border: none;
    max-width: 550px;
    border-radius: 0;

    .inner-wrap {
      padding: 0 8px ;
      &-info {
        border-bottom: 1px solid #cbd1d9 !important;
        padding: 16px 0;
        .duration{
          color: #0D1321 !important;
          margin-top: 8px;
          font-size: 14px;
          font-weight: 500;
          @media only screen and (min-width: 1600px) {
            font-size: 16px;
          }
        }
      }
      .whatact {
        color: #42474d;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 300;
        @media only screen and (min-width: 1600px) {
          font-size: 16px;
        }
      }
      .debt{
        // border-top: 1px solid #cbd1d9 !important;
        padding-top: 24px;

      }
    }

    .list {
      color: #5a5e64;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      @media only screen and (min-width: 1600px) {
        font-size: 16px;
      }
    }
  }
}

.right-wrapper {
  width: 100%;
  max-width: 416px;
  background-color: #272d35;
  border-radius: 16px;
  text-align: center;
  padding: 56px;
  @media only screen and (max-width: 1440px) {
    padding: 36px

  }
  @media only screen and (max-width: 992px) {
    padding: 24px 16px;

  }

  h5 {
    color: #b9babd;
    margin-bottom: 8px;
    font-size: 18px;
    @media only screen and (min-width: 1600px) {
      font-size: 20px;
    }
  }
  h3 {
    color: #ffffff;
    margin-bottom: 16px;
    font-size: 40px;
    font-weight: 600;
    @media only screen and (min-width: 1600px) {
      font-size: 42px;
    }
  }
  .chips {
    color: #d0d1d3;
    margin-bottom: 8px;
    font-size: 12px;
    border: 1px solid #42474d;
    padding: 4px 8px 4px 6px;
    border-radius: 32px;
  }
  .points {
    text-align: left;
    h6 {
      color: #DFE5ED;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 500;
    }
    ul {
      li {
        color: #a2a8b0;
        // margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .pay-btn {
    border-top: 1px solid #42474d;
    padding-top: 24px;

    .full-btn {
      background-color: #279f63;
      border: 1px solid #279f63;
      padding: 10px 16px;
      border-radius: 4px;
      &:hover {
        background-color: #279f63;
      }
    }

    .partial-btn {
      background-color: #2968dd;
      border: 1px solid #2968dd;

      &:hover {
        background-color: #2968dd;
      }
    }

    .plan-btn {
      border: 1px solid #1b4eab;
      background-color: transparent;
      
      &:hover {
        background-color: #1b4eab;
      }
    }
  }
}
.discount_settlement{
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #37CE82 !important;
}
.totalServiceAmount{
  font-size: 24px !important;
  font-weight: 400 !important;
  color: #F57373 !important;
  text-decoration: line-through;
  padding-left: 8px !important;
}

.more-services{
  background-color: #42474D !important;
  border: 1px solid #A1A3A6 !important;
  color:#D0D1D3 !important;
  padding: 4px 8px !important;
  border-radius: 32px;
  

}

  .tooltip-inner{
    background-color: #FFFFFF !important;
    color: #131921 !important;
    box-shadow: 0px 4px 8px 0px #0000003D !important; 
    min-width: 154px !important;
   }
   .tooltip .tooltip-arrow::before {
    border-right-color: #FFFFFF !important;
   }
   