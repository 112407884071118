@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Mulish:wght@200..1000&family=Playwrite+AR:wght@100..400&family=Playwrite+AT:ital,wght@0,100..400;1,100..400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Noto+Serif+JP:wght@200..900&family=Playwrite+AT:ital,wght@0,100..400;1,100..400&family=Playwrite+HR:wght@100..400&family=Playwrite+HU:wght@100..400&display=swap");

.payment-wrapper {
  // height: calc(100vh - 75px);
  width: 100%;
  max-width: 620px;
  margin:  0 auto;

@media screen and (max-width: 575px) {
  max-width: 100%;
}
}

.font-icon {
  animation: pulse 2s ease-in-out infinite;
  border: 1px solid rgba(114, 189, 114, 0.8);

  &:hover {
    animation: none;
  }
}

@keyframes pulse {
  50% {
    transform: scale(0.7);
    box-shadow: 0 0 0 15px rgba(114, 189, 114, 0.2);
  }
}

@media screen and (max-width: 575px) {
  .heading-text {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
  }

  .switch {
    height: 22px;
    width: 35px;

    .slider {
      &:before {
        height: 15px;
        width: 15px;
        left: 3px;
        bottom: 4px;
      }
    }

    input:checked + .slider:before {
      transform: translateX(14px);
    }
  }

  .fs-18 {
    font-size: 16px;
  }
}

.card-images {
  img {
    width: 30px;
    height: 30px;
  }
}

.main-header {
  // padding-bottom: 24px;
  h2 {
    color: #0d1321;
  }
  p {
    color: #42474d;
    font-size: 16px;
    max-width: 451px;
    margin: 0 auto;
    width: 100%;
    margin-top: 8px;
  }
}
.mid-header {
  .since-wrapper {
    border: 1px solid #dfe5ed;
    padding: 8px 11px;
    border-radius: 48px;
  }
  .since {
    color: #5a5e64;
    font-size: 16px;
    margin-bottom: 8px;
  }
  .debtdate {
    color: #2b3038;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 500;
  }
}
.paymentType{
  label{
    margin-bottom: 8px;
    font-size: 16px;
    color: #2B3038;
    margin-bottom: 16px;
  }
}
.palnDuration{
  background-color: #E8F9F1;
  padding: 6px 12px;
  border-radius: 24px;
  max-width: 200px;
  margin:  0 auto 0;
  h5{
    color: #2B3038;
    font-size: 14px;
    margin-bottom: 0;
  }


}

#signature-text{
  display: block;
  line-height: 60px;
  padding: 16px 0 30px;
}
.custom-checkbox{
  border : 1px solid #B6BCC4;
  border-radius: 10px;
  padding-top: 16px;
}

.custom-checkbox input[type="checkbox"] {
  position: absolute;
  top: 17px;
  left: -7px;
  margin-left: 7px;
  background-color: #fff;
  z-index: 999;
  border : 1px solid #B6BCC4;

}
.form-check-input:checked[type=checkbox] {
  background-color: #37CE82;
  border : 1px solid #37CE82;

}


.custom-checkbox input[type="checkbox"]:focus,
.custom-checkbox input[type="checkbox"]:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}