.payment-reminder-container {
  max-width: 416px;
  margin: auto;
  text-align: center;
  padding-top: 48px;
  @media only screen and (max-width:1240px)  {
    padding: 24px;
    
  }

  .title {
    font-weight: #0d1321;
    font-size: 24px;
    font-weight: 600;
  }

  .subtitle {
    color: #6c757d;
    font-size: 14px;
    font-weight: 500;
    padding-top: 8px;
  }

  .payment-info {
    padding: 26px 0;
    .recurring-text {
      font-size: 14px;
      color: #6c757d;
    }

    .amount {
      font-size: 48px;
      font-weight: 600;
      color: #0D1321;
    }

    .due-badge {
      background: #ffcc80;
      color: #42474d;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .payment-method {
    text-align: left;
    margin-top: 20px;

    .label {
      font-weight: bold;
    }

    .description {
      font-size: 14px;
      color: #6c757d;
      width: 100%;
      max-width: 238px;
      @media only screen and (max-width:1240px)  {
        max-width: 215px;
        
      }
    }

    .payment-card {
      display: flex;
      align-items: center;
      gap: 2px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      max-width: 250px;
      margin-top: 10px;

      .card-logo {
        font-size: 24px;
      }

      .card-number {
        font-weight: bold;
      }

      .edit-icon {
        cursor: pointer;
        color: #007bff;
      }
    }
  }
}
