.payment-success {
    text-align: center;
    max-width: 500px;
    margin: 50px auto;
    
    // .success-icon {
    //   background-color: #28a745;
    //   color: white;
    //   width: 70px;
    //   height: 70px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   border-radius: 50%;
    //   font-size: 30px;
    //   margin: 20px auto;
    //   position: relative;
      
    //   &::after {
    //     content: "";
    //     position: absolute;
    //     width: 100px;
    //     height: 100px;
    //     // background: url('/path/to/confetti.png') no-repeat center;
    //     background-size: contain;
    //   }
    // }
  
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin: 15px 0;
    }
  
    p {
      color: #6c757d;
      margin-bottom: 15px;
    }
  
    hr {
      width: 50px;
      margin: 10px auto;
      border: 1px solid #ddd;
    }
  
    .dashboard-btn {
      background-color: #28a745;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      margin: 15px 0;
      border-radius: 5px;
      &:hover {
        background-color: #218838;
      }
    }
  
    .download-receipt {
      display: block;
      color: #2c52fc;
      text-decoration: none;
      font-weight: bold;
      margin-top: 10px;
      &:hover {
        text-decoration: underline;
      }
    }
  }