@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Outfit:wght@100..900&display=swap");

.overlay {
  background: #0008;
  z-index: 1056 !important;
  left: 0;
  top: 0;
}
.fm-spinner {
  background: #183813;
  height: 60px;
  width: 60px;
  animation: rotate-spinner 1s linear infinite;
  filter: drop-shadow(3px 0px 2px #fff);
  // border-top: 3px solid #fff;
  // #ace7b0
}

@keyframes rotate-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin scroll($bgColor, $shadow, $display) {
  &::-webkit-scrollbar {
    width: 5px;
    height: 12px;
    box-shadow: none;
    background-color: $bgColor;
    display: $display;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: transparent;
    box-shadow: $shadow;
  }

  &:hover,
  :focus {
    &::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0.12);
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.25);
    }
  }
}

* {
  font-family: "Outfit", sans-serif;
  font-feature-settings: 'liga' off;
  @include scroll(transparent, none, unset);
  font-feature-settings: 'liga' off;

}
body {
  line-height: 1.5em;
}

.show-scroll-bar {
  @include scroll(
    rgba(0, 0, 0, 0.12),
    inset 0 0 0 12px rgba(0, 0, 0, 0.25),
    unset
  );
}

.hide-scroll-bar {
  max-height: calc(100vh - 160px) !important;
  @media only screen and (max-width: 992px) {
    height: calc(100vh - 200px) !important;
  }
  @include scroll(transparent, none, none);
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}
.fs-32 {
  font-size: 32px;
}
.fs-36 {
  font-size: 36px;
}
.fs-40 {
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
}
.fs-46 {
  font-size: clamp(36px, 5vw, 46px);
}
.fs-60 {
  font-size: clamp(24px, 4vw, 60px);
}
.fs-46-sm {
  @media screen and (max-width: 768px) {
    font-size: 36px;
  }
}
@media screen and (min-width: 768px) {
  .fs-md-24 {
    font-size: 24px;
  }
}
.blue-gradient {
  // background: linear-gradient(
  //   238.36deg,
  //   #ffffff 0.86%,
  //   #f1fff7 22.08%,
  //   #dcffea 48.08%,
  //   #f1fff7 82.65%,
  //   #ffffff 100%
  // );
}
.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}
.hw-20 {
  height: 20px;
  width: 20px;
}
.hw-24 {
  height: 24px;
  width: 24px;
}
.hw-32 {
  height: 32px;
  width: 32px;
}
.hw-40 {
  height: 40px;
  width: 40px;
}
.hw-56 {
  height: 56px;
  width: 56px;
}
.hw-80 {
  height: 80px;
  width: 80px;
}
.hw-95 {
  height: 95px;
  width: 95px;
}

.grey-text {
  color: #7e7e7e;
}

.dark-grey {
  color: #464747;
}

.bg-lightgrey {
  background: #f8f8f8;
}
.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 991;
  transition: all 0.3s ease-in-out;
}

.input_field {
  border-radius: 8px;
  padding: 17px 16px;
  margin: 8px 0;
  border: 1px solid #cbd1d9;
}

.link-text {
  color: #4b58ca;
}
.cursor {
  cursor: pointer;
}

// ---------------------Radio Buttons

/* Common Styles for Radio Buttons */
input[type="radio"] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
  font-size: 14px;
}

input[type="radio"] + label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 50%;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  opacity: 1;
}

/* Unchecked Radio Button */
input[type="radio"] + label:before {
  border: 2px solid #c3c8cd;
  background-color: #fff;
}

/* Primary Radio Button */
input[type="radio"].primary-radio:checked + label:before {
  background-color: #fff;
  border: 2px solid #ffe66d;
}

input[type="radio"].primary-radio:checked + label:after {
  background-color: #ffe66d;
}

/* Secondary Radio Button */
input[type="radio"].secondary-radio:checked + label:before {
  border: 2px solid #275b1f;
  background: #f7faf7;
}

input[type="radio"].secondary-radio:checked + label:after {
  background-color: #275b1f;
}

@keyframes rotate-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.decoration-none {
  text-decoration: none;
  list-style: none;
}
.label-primary {
  color: #0a6b89;
}

.label-success {
  color: #00bc8b;
}

.label-danger {
  color: #ff0909;
}

.label-warning {
  color: #ff902b;
}

.dropdown-options {
  z-index: 2;
  top: 36px;
  border-radius: 10px;
  min-width: 170px;
  box-shadow: 0px 2px 8px -1px black;
  li {
    &:hover {
      background: #a5f3c4;
    }
  }
}
.modal-dialog {
  pointer-events: unset;
}

.switch {
  position: relative;
  display: inline-block;
  height: 24px;
  width: 50px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #37CE82;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.form-control:focus{
    outline: none;
    box-shadow: none;
}
.form-check-input:checked{
    background-color: #37CE82;
    border-color: #37CE82;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.radius-4 {
  border-radius: 4px;
}
.radius-8 {
  border-radius: 8px;
}
.radius-16 {
  border-radius: 16px;
}
.toggle-arrow {
  transition: all 0.4s ease-in-out;
  &.rotate {
    transform: rotate(-180deg);
  }
}

.remove-border.active,
.remove-border:focus,
.remove-border-item:hover {
  color: #000;
  background-color: unset;
}
.btn {
  padding: 10px 24px;
//   min-width: 100px;
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }
  &.btn-primary {
    background-color: #279f63;
    border: 1px solid #279f63;
    color: #fff;
    font-weight: 500;
  }

  &.btn-secondary {
    background: rgb(255, 193, 7);
    border: 1px solid rgb(255, 193, 7);
    color: #000;
    font-weight: 700;
  }
  &.btn-outline {
    border: 1px solid #d0d5dd;
    background: #fff;
    color: #000;
  }
  &.disabled {
    opacity: 0.5;
    cursor: unset;
    background-color: #e8e8e9;
  }
}
.btn:disabled {
  background-color: #e8e8e9 !important;
  color: #898C90;
  border: none;
}
.btn-close {
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  color: #d8d8d8;
  box-shadow: none !important;
  font-size: 10px;
}

.mxw-767{
  @media only screen and (min-width: 1024px){
    max-width: 767px;
  }
}

.mxw-520 {
  max-width: 520px !important;
}
.mxw-451 {
  max-width: 451px !important;
}
.mxw-460 {
    max-width: 460px;
  }
  .mxw-416 {
    max-width: 416px;
  }

.mxw-300 {
  max-width: 300px;
}

.fs-30 {
  font-size: 30px;
}

.fs-10 {
  font-size: 10px;
}
.break-all {
  word-break: break-all;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.footer-continue {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #fff;
}


// Floating input labels

.floating-label {
  position: relative;
  margin-bottom: 8px;
}

.styled-input {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 17px 16px;
  font-size: 16px;
  outline: none;
  background: none;
}

.styled-input:focus,
.styled-input:not(:placeholder-shown) {
  border-color: #797F87;
  ;
}

.floating-label label {
  position: absolute;
  top: 50%;
  left: 11px;
  transform: translateY(-50%);
  background: white;
  padding: 0 4px;
  font-size: 14px;
  color: #a8a8a8;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.styled-input:focus + label,
.styled-input:not(:placeholder-shown) + label {
  top: 0px;
  left: 12px;
  font-size: 12px;
  color: #898C90;
}


.four-dots {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999999;

  &:before {
      display: block;
      height: 5px;
      width: 5px;
      content: "";
      animation: spin 1.2s infinite;
      border-radius: 100%;
      box-shadow: 20px 0 0 5px #2897CA, 0 20px 0 5px #37CE83, 0 -20px 0 5px #37CE83,
          -20px 0 0 5px #2897CA;
      top: 50%;
      left: 50%;
      position: absolute;
  }
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.text-danger{
  color: red !important;
}

  p#signature-text {
    transform: rotate(0deg) !important;

  }
