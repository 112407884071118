.copyright-footer {
  background-color: #f8f9fa;
  text-align: center;
  padding: 10px 0;
  font-size: 0.9rem;
  color: #6c757d;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.copyright-text {
  margin: 0;
}
