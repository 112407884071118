.partial-wrapper {
    border: 1px solid #dfe5ed;
    padding: 6px 11px;
    border-radius: 48px;
    width: 100%;
    max-width: 266px;
    margin: 0 auto;
  }
  .partial-date {
    color: #5a5e64;
    font-size: 16px;
    margin-bottom: 8px;
  }