.uploaded-img {
    display: block;
    max-width: 80px;
    padding-left: 16px;

    img {
        width: 50px;
        height: 50px;
    }
    .deleteCross {
        width: 20px;
        height: 20px;
        color: white;
        border: 1px solid #797F87;
        /* filter: brightness(0.5); */
        border-radius: 50%;
        padding: 4px;
        position: absolute;
    }
}
.floating-label.has-error .styled-input {
    border-color: red; /* Red border for error */
  }
  
  .floating-label.has-error label {
    color: red; /* Optional: Make the label red */
  }
  
  .error-border {
    border: 1px solid red; /* Red border for input field */
  }
