.recurring-payment-container {
  color: #333;
  padding-top: 48px;
  line-height: normal;
  width: 100%;

  .reminder-box {
    border-radius: 8px;
    // max-width: 416px;

    .greeting {
      font-weight: 600;
      color: #5a5e64;
    }

    .highlight {
      color: #1c7347;
    }

    .service-section {
      margin-top: 32px;
      .service-label {
        font-size: 14px;
        color: #5a5e64;
        padding-bottom: 3px;
      }

      .service-tags {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
      }
      .service-badge {
        background-color: #f2f5f8;
        border-radius: 32px;
        padding: 4px 6px;
        font-size: 14px;
      }
    }

    .auto-debit,
    .pay-off {
      border-top: 1px solid #cbd1d9;
      padding-top: 24px;
      .section-title {
        font-weight: 600;
      }

      .date-text {
        font-size: 14px;
        color: #666;
      }
      &-now{
        background-color: #ffff;
        border: 1px solid #7CB1FF;
        border-radius: 4px;
        color: #2968DD;
      
      }
    }
  }

  .payment-summary {
    background: #272d35;
    color: #fff;
    border-radius: 16px;
    padding: 40px;
    text-align: center;
    .due-indicator {
      .line {
        border-top: 2px solid #e3b78f;
        flex-grow: 1;
        &.missing {
          color: #f79999;
          border: 1.5px solid #f79999;
        }
      }

      .due-text {
        border: 1.5px solid #febe7e;
        color: #febe7e;
        border-radius: 20px;
        padding: 4px 12px;
        font-size: 14px;
        font-weight: 500;
        background: transparent;
        display: inline-block;
        white-space: nowrap;
        transition: background 0.3s ease;

        &.missing {
          color: #f79999;
          border: 1.5px solid #f79999;
        }
      }
    }

    .payment-amount {
      font-size: 40px;
      font-weight: 600;
    }

    .payment-cycle {
      color: #b9babd;
      font-size: 18px;
      margin-bottom: 8px;
    }
    .discount-msg {
      color: #60d89b;
    }

    .due-indicator {
      font-size: 12px;
      border-radius: 12px;
      padding: 4px 10px;
      margin-top: 16px;
    }

    .details {
      p {
        margin: 5px 0;
      }
      .active-status {
        color: #1cd968;
        &.overDue {
          color: #fd952d;
        }
      }
      strong {
        color: #b9babd;
        font-weight: 400;
      }
      .contractId,
      .discounted,
      .enrollment-date {
        color: #DFE5ED;
        font-weight: 500;
      }

      .original-amount {
        text-decoration: line-through;
        color: #ccc;
        color: #f79999;
      }
    }
    .pay-now {
      padding: 14px 0;
      background-color: #279f63;
    }
    .arrow {
      border: 2px solid #b9babd;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
    }

    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }

  .discount-container {
    position: relative;
  }

  .discount-header {
    // background-color: #1e1e1e;
    color: #5bc45c;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .discount-msg {
      display: flex;
      align-items: center;
      font-weight: 600;

      img {
        width: 16px;
        margin-right: 8px;
      }
    }

    .arrow {
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      transition: transform 0.3s ease-in-out;

      &.right {
        transform: rotate(-45deg);
      }

      &.down {
        transform: rotate(45deg);
      }
    }
  }

  .discount-tooltip {
    background: white;
    color: #333;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    margin-top: 5px;
    width: 300px;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    /* left: 0; */
    right: 57px;
    z-index: 10;
  }
  .keepDiscount {
    padding-top: 18px;
    h5 {
      color: #f79999;
      font-size: 14px;
      font-weight: 500;
    }
    p{
      color: #5A5E64;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}
